body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.MuiOutlinedInput-root{
  border-radius: 40px !important;
}

div.MuiInputBase-adornedStart button span {
  margin: 0px !important;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #adadad #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .right-triangle {
    padding: 0;
    margin: 0;
  }

  .right-triangle:after {    
    content: '';
    border-bottom: 10vh solid #ff1744;
    border-left: 100vw solid transparent;
    width: 0;
    position: absolute;
    right: 0px;
  }